import { useMobile } from "../../utils/viewport";
import Header from "./Header/Header";
import MobileHeader from "./Header/MobileHeader/MobileHeader";

const HeaderSelector = () => {
  const isSmallScreen = useMobile();

  return isSmallScreen ? <MobileHeader /> : <Header />;
};

export default HeaderSelector;
