import classNames from "classnames";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { useMobile } from "../../utils/viewport";
import styles from "./Site.module.scss";

const Main = ({ children }) => {
  const isSmallScreen = useMobile();

  const userLoggedIn = useSelector(state => {
    return state.session?.sessionId;
  });

  return (
    <main className={classNames(styles.main, isSmallScreen && userLoggedIn && styles.loggedInMobile)}>{children}</main>
  );
};

Main.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Main;
