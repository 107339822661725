import { AnyAction } from "redux";
import {
  CLEAR_SELECTED_COMPANIES,
  CONTENT_EMBEDDED,
  REMOVE_SCROLL_POSITION,
  SAVE_SCROLL_POSITION,
  SEARCH_STRING,
  SELECTED_CATEGORY,
  TOGGLE_SELECTED_COMPANY,
} from "./settingVariables";

interface ScrollPosition {
  key: string;
  position: number;
}

interface SettingState {
  searchString: string;
  selectedCategory: string;
  selectedCompanies: string[];
  savedScrollPositions: ScrollPosition[];
  contentEmbedded: any;
}

const initialState: SettingState = {
  searchString: "",
  selectedCategory: "",
  selectedCompanies: [],
  savedScrollPositions: [],
  contentEmbedded: null,
};

const settingReducer = (state: SettingState = initialState, action: AnyAction): SettingState => {
  const newSelectedCompanies: string[] = [...state.selectedCompanies];

  switch (action.type) {
    case SEARCH_STRING:
      return {
        ...state,
        searchString: action.searchString,
      };
    case SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: action.selectedCategory,
      };
    case TOGGLE_SELECTED_COMPANY:
      if (newSelectedCompanies.indexOf(action.selectedCompany) > -1) {
        newSelectedCompanies.splice(newSelectedCompanies.indexOf(action.selectedCompany), 1);
      } else {
        newSelectedCompanies.push(action.selectedCompany);
      }

      return {
        ...state,
        selectedCompanies: newSelectedCompanies,
      };
    case SAVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.concat({
          key: action.key,
          position: action.position,
        }),
      };
    case REMOVE_SCROLL_POSITION:
      return {
        ...state,
        savedScrollPositions: state.savedScrollPositions.filter(scrollPosition => scrollPosition.key !== action.key),
      };
    case CLEAR_SELECTED_COMPANIES:
      return {
        ...state,
        selectedCompanies: [],
      };
    case CONTENT_EMBEDDED:
      return {
        ...state,
        contentEmbedded: action.contentEmbedded,
      };
    default:
      return state;
  }
};

export default settingReducer;
