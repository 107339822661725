import { Button, Form, InputField, Modal } from "@myloc/myloc-gui";
import PropType from "prop-types";
import { useEffect, useState } from "react";
import { useTranslate } from "../../../language/i18n";
import clientService from "../../../services/client/clientService";
import isEqual from "../../../utils/compareObjects";
import { AdvanceContactInformation } from "../../shared/RecieverInformation/RecieverInformation";
import styles from "../ClientsPage.module.scss";

const EditClientModal = ({ visible, client, onClose, setFetchReceiver }) => {
  const translate = useTranslate();
  const [contactInfo, setContactInfo] = useState({
    phoneNo: client.contactInformation?.phoneNo,
    email: client.contactInformation?.email,
  });
  const [loading, setLoading] = useState(false);
  const [useAdvanceContact, setUseAdvanceContact] = useState(client.useAdvanceContact);

  useEffect(() => {
    setContactInfo({ phoneNo: client.contactInformation?.phoneNo, email: client.contactInformation?.email });
    setUseAdvanceContact(client.useAdvanceContact);
  }, [client]);

  const handleFieldChange = (event, key) => {
    setContactInfo({ ...contactInfo, [key]: event.target.value });
  };

  const handleSubmit = () => {
    if (
      contactInfo &&
      isEqual(client.contactInformation, contactInfo) &&
      client.useAdvanceContact === useAdvanceContact
    ) {
      onClose();
    } else {
      setLoading(true);
      clientService.updateClient(client.id, { contactInformation: contactInfo, useAdvanceContact }).then(() => {
        setLoading(false);
        setFetchReceiver(true);
        onClose();
      });
    }
  };

  return (
    <Modal visible={visible} title={translate("EDIT_CLIENT")} onClose={onClose} customCssClass={styles.modal}>
      <Form onSubmit={handleSubmit} className={styles.form}>
        <InputField
          label={translate("PHONE_NO")}
          name="phoneNo"
          type="text"
          value={contactInfo?.phoneNo}
          onChange={e => handleFieldChange(e, "phoneNo")}
          autoFocus
        />
        <InputField
          label={translate("EMAIL")}
          name="email"
          type="text"
          value={contactInfo?.email}
          onChange={e => handleFieldChange(e, "email")}
        />
        <AdvanceContactInformation shouldContact={useAdvanceContact} onChange={setUseAdvanceContact} />
        <Button type="submit" isLoading={loading} customCssClass={styles.saveBtn}>
          {translate("SAVE")}
        </Button>
      </Form>
    </Modal>
  );
};

EditClientModal.propTypes = {
  visible: PropType.bool,
  client: PropType.shape({
    id: PropType.string,
    contactInformation: PropType.shape({
      phoneNo: PropType.string,
      email: PropType.string,
    }),
    useAdvanceContact: PropType.bool,
  }),
  onClose: PropType.func,
  setFetchReceiver: PropType.func,
};

export default EditClientModal;
