import QRCode from "qrcode.react";
import { ReactNode, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import classNames from "classnames";
import logo from "../../../assets/logos/HÖS/HÖS.jpg";
import { useTranslate } from "../../../language/i18n";
import { setErrorAction } from "../../../reducers/dialog/dialogAction";
import { useAppDispatch } from "../../../reducers/hooks/useAppDispatch";
import { RootState } from "../../../reducers/rootReducer";
import pages from "../../../utils/pages";
import { useTablet } from "../../../utils/viewport";
import Image from "../../shared/Image/Image";
import SpinningButton, { CONTAINED, OUTLINED, START } from "../Buttons/MuiButton/MuiButton";
import LoadingSpinner from "../Spinner/LoadingSpinner";
import { ReactComponent as BankIDIcon } from "./assets/images/BankID_logo_black.svg";
import { ReactComponent as BankIDIconWhite } from "./assets/images/BankID_logo_white.svg";
import {
  BANKID,
  COMPLETE,
  EXPIRED_TRANSACTION,
  FAILED,
  OUTSTANDING_TRANSACTION,
  START_FAILED,
  UNKNOWN,
  USER_SIGN,
} from "./constants";
import { detectBrowser, useBankId } from "./hooks/useBankId";
import styles from "./style/bankId.module.scss";
import { BankIdCollectData } from "./types";

interface SpinnerProps {
  hide: boolean;
}

const Spinner = ({ hide }: SpinnerProps) => {
  return <div className="spinnerPlaceholder">{hide ? null : <LoadingSpinner />}</div>;
};

interface FailedContentProps {
  collectedResponse?: BankIdCollectData;
  action: () => void;
  cancelled?: boolean;
}

const navigateToBankId = () => {
  window.location.href = pages.BANKID_LOGIN.PATH;
};

const Logo = () => {
  return (
    <div className={styles.logo}>
      <Image
        src={logo}
        alt="Hös - Logotyp"
        customCssClass={styles.imageContainer}
        isOrderable={undefined}
        infoMessage={undefined}
      />
    </div>
  );
};

const MILLISECONDS_IN_SECOND = 1000;
const THREE = 3;

const BankIdButtonThisDevice = ({
  isPrimary,
  text,
  showIcon,
}: {
  isPrimary: boolean;
  text: string;
  showIcon: boolean;
}) => {
  const [loading, setLoading] = useState(false);
  const [clickedThisDevide, setClickedThisDevide] = useState(false);
  const { bankIdUrl, initAuth, resetBankIdUrl } = useBankId();
  const dispatch = useAppDispatch();
  const translate = useTranslate();

  useEffect(() => {
    if (!bankIdUrl) return;

    const timeout = setTimeout(() => {
      setLoading(false);
      dispatch(
        setErrorAction({
          error: "exception",
          errorMessage: translate("THE_BANKID_APP") + " " + translate("DIDNT_START"),
        }),
      );
    }, THREE * MILLISECONDS_IN_SECOND); // Let the BankID app try to start for a while before show error

    window.location.href = bankIdUrl;

    const handleBlur = () => {
      clearTimeout(timeout);
      setLoading(false); // Stop loading when the app successfully launches (focus is lost)
    };

    //If we blur, we know that we lost focus, probably because BankID application exists and was launched
    //At blur, remove timer since we started - should only need 3 sec max
    window.addEventListener("blur", handleBlur);

    return () => {
      clearTimeout(timeout);
      window.removeEventListener("blur", handleBlur);
    };
  }, [bankIdUrl, dispatch, translate]);

  const onReloadWholePage = () => {
    window.location.reload();
  };

  const onClickUseThisDevice = () => {
    setLoading(true);
    setClickedThisDevide(true);

    if (bankIdUrl) {
      resetBankIdUrl(); //Need to replace current url with a new one
    }

    initAuth();
  };

  return (
    <>
      {showIcon ? (
        <SpinningButton
          variant={isPrimary ? CONTAINED : OUTLINED}
          loading={loading}
          buttonAction={clickedThisDevide && detectBrowser() === UNKNOWN ? onReloadWholePage : onClickUseThisDevice}
          icon={isPrimary ? <BankIDIconWhite /> : <BankIDIcon />}
          iconPosition={START}
        >
          {text}
        </SpinningButton>
      ) : (
        <SpinningButton
          variant={isPrimary ? CONTAINED : OUTLINED}
          loading={loading}
          buttonAction={clickedThisDevide && detectBrowser() === UNKNOWN ? onReloadWholePage : onClickUseThisDevice}
        >
          {text}
        </SpinningButton>
      )}
    </>
  );
};

export const BankIDLoginAlternatives = () => {
  const notDesktop = useTablet();
  const translate = useTranslate();

  return (
    <>
      <div className={classNames(styles.sectionTitle, styles.textAlignLeft)}>
        {translate("HOW_WOULD_YOU_LIKE_TO_LOG_IN")}
      </div>
      {notDesktop ? (
        <>
          <BankIdButtonThisDevice isPrimary={true} text={translate("OPEN") + " " + BANKID} showIcon={true} />
          {translate("OR")}
          <SpinningButton buttonAction={navigateToBankId} variant={OUTLINED}>
            {translate("OPEN") + " " + BANKID + translate("ON_ANOTHER_DEVICE")}
          </SpinningButton>
        </>
      ) : (
        <>
          <SpinningButton
            icon={<BankIDIconWhite />}
            iconPosition={START}
            buttonAction={navigateToBankId}
            variant={CONTAINED}
          >
            {translate("MOBILE_BANKID")}
          </SpinningButton>
          {translate("OR")}
          <BankIdButtonThisDevice
            isPrimary={false}
            text={translate("OPEN") + " " + BANKID + " " + translate("ON_THIS_DEVICE")}
            showIcon={false}
          />
        </>
      )}
    </>
  );
};

const FailedContent = ({ collectedResponse, action, cancelled }: FailedContentProps) => {
  const translate = useTranslate();

  const getMessage = () => {
    if (cancelled) {
      return translate("THE_AUTHENTICATION") + " " + translate("WAS_CANCELLED");
    }

    if (collectedResponse?.status === FAILED && collectedResponse.hintCode === START_FAILED) {
      return translate("THE_AUTHENTICATION") + " " + translate("DIDNT_START");
    }

    if (collectedResponse?.status === FAILED) {
      return translate("THE_AUTHENTICATION") + " " + translate("FAILED");
    }

    return translate("UNKNOWN_ERROR_OCCURRED");
  };

  return (
    <div className={styles.bankIdWrapper}>
      <div className={classNames(styles.bankIdContentText, styles.textCentered, styles.withGap)}>
        <p>{getMessage()}</p>
        <SpinningButton buttonAction={action} variant={OUTLINED}>
          {translate("TRY_AGAIN")}
        </SpinningButton>
      </div>
    </div>
  );
};

interface PendingContentProps {
  collectedResponse?: BankIdCollectData;
}

const PendingContent = ({ collectedResponse }: PendingContentProps) => {
  const translate = useTranslate();
  let msg: string;

  if (!collectedResponse) {
    msg = translate("WAITING_FOR") + " " + BANKID + "...";
  } else if (collectedResponse.status === COMPLETE) {
    msg =
      translate("AUTHENTICATION") +
      " " +
      translate("WITH") +
      " " +
      BANKID +
      " " +
      translate("SUCCEEDED") +
      ", " +
      translate("REDIRECTING") +
      "...";
  } else if (collectedResponse.status === FAILED) {
    msg = translate("AUTHENTICATION") + " " + translate("WITH") + " " + BANKID + " " + translate("FAILED");
  } else if (collectedResponse.hintCode === USER_SIGN) {
    msg = translate("WAITING_FOR") + " " + translate("AUTHENTICATION") + "...";
  } else {
    msg = translate("UNKNOWN_ERROR_OCCURRED");
  }

  return (
    <>
      <Spinner hide={false} />
      <>{msg}</>
    </>
  );
};

const QRCodeContainer = ({ qrCodeContent }: { qrCodeContent: string }) => {
  return <div className={styles.bankIdContentQR}>{qrCodeContent && <QRCode size={128} value={qrCodeContent} />}</div>;
};

const Instructions = () => {
  const translate = useTranslate();

  return (
    <ol className={styles.bankIdContentText}>
      <li>{translate("OPEN") + " " + translate("THE_BANKID_APP") + " " + translate("ON_YOUR_DEVICE")}</li>
      <li>{translate("PUSH_SCAN_QR_CODE")}</li>
      <li>{translate("POINT_THE_CAMERA_AT_THE_QR_CODE_ON_SCREEN")}</li>
    </ol>
  );
};

const InitContent = ({ qrCodeContent }: { qrCodeContent: string }) => {
  const translate = useTranslate();

  return (
    <>
      <div className={styles.bankIdWrapper}>
        <QRCodeContainer qrCodeContent={qrCodeContent} />
        <Instructions />
      </div>
      <BankIdButtonThisDevice
        isPrimary={false}
        text={translate("OPEN") + " " + BANKID + " " + translate("ON_THIS_DEVICE")}
        showIcon={false}
      />
    </>
  );
};

const navigateHome = () => {
  window.location.href = pages.HOME;
};

const CancelContent = () => {
  const translate = useTranslate();

  return (
    <div className={styles.cancelContent} onClick={navigateHome}>
      {translate("BACK")}
    </div>
  );
};

interface BankIdContainerProps {
  children: ReactNode;
}

const BankIdContainer = ({ children }: BankIdContainerProps) => {
  const notDesktop = useTablet();
  const translate = useTranslate();

  return (
    <div className={styles.authenticationContainer}>
      <div className={styles.bankIDContainerTitle}>
        {notDesktop ? (
          <>
            <div className="bankIDHeading">{translate("AUTHENTICATE_WITH") + " " + BANKID}</div>
            <BankIDIcon className={styles.titleIcon} />
          </>
        ) : (
          <>
            <BankIDIcon className={styles.titleIcon} />
            <div className="bankIDHeading">{translate("AUTHENTICATE_WITH") + " " + BANKID}</div>
          </>
        )}
      </div>
      {children}
      <CancelContent />
    </div>
  );
};

const BankIdAuthentication = () => {
  const initialized = useSelector((state: RootState) => state.session.initialized);
  const sessionId = useSelector((state: RootState) => state.session.sessionId);
  const {
    autoStartToken,
    bankIdContent,
    cancelled,
    collectedResponse,
    initAuth,
    isBankIdError,
    qrCodeContent,
    restart,
    loginMethodsInitialized,
  } = useBankId();

  useEffect(() => {
    if (!autoStartToken && initialized && !cancelled && !sessionId && loginMethodsInitialized) {
      initAuth();
    }
  }, [autoStartToken, cancelled, initAuth, initialized, loginMethodsInitialized, sessionId]);

  if (sessionId) {
    window.open(pages.HOME, "_self");
  }

  return (
    <>
      <nav className={styles.navBar}></nav>
      <div className={styles.container}>
        <BankIdContainer>
          {cancelled || isBankIdError() ? (
            <FailedContent action={restart} />
          ) : !autoStartToken || !bankIdContent ? (
            <PendingContent />
          ) : qrCodeContent && collectedResponse?.hintCode === OUTSTANDING_TRANSACTION ? (
            <InitContent qrCodeContent={qrCodeContent} />
          ) : collectedResponse?.hintCode === EXPIRED_TRANSACTION || collectedResponse?.status === FAILED ? (
            <FailedContent collectedResponse={collectedResponse} action={restart} />
          ) : (
            <PendingContent collectedResponse={collectedResponse} />
          )}
        </BankIdContainer>
        <Logo />
      </div>
    </>
  );
};

export default BankIdAuthentication;
