import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { selectHasComflowAccess } from "../../../reducers/session/sessionSelectors";
import pageContentService from "../../../services/pageContent/pageContentService";
import { useTablet } from "../../../utils/viewport";
import LinkToComflow from "../../Comflow/LinkToComflow";
import FileDownloadList from "../../shared/File/FileDownload/FileDownload";
import { ExternalLinkList, Target } from "../../shared/Link";
import styles from "./ContentWelcome.module.scss";
import Welcome from "./Welcome";

const ContentWelcome = () => {
  const [pageContent, setPageContent] = useState({});
  const notDesktop = useTablet();
  const hasComflowAccess = useSelector(selectHasComflowAccess);

  useEffect(() => {
    pageContentService.getPageContent().then(response => setPageContent(response.data));
  }, []);

  return (
    <>
      {hasComflowAccess && notDesktop && <LinkToComflow />}
      <Welcome title={pageContent?.title ? pageContent.title : ""} text={pageContent?.text}>
        <h2 className={styles.sectionTitle}>{pageContent?.hyperlinkTitle}</h2>
        {pageContent?.hyperlinks && (
          <ExternalLinkList
            externalLinks={pageContent.hyperlinks}
            target={Target.NEW_TAB}
            customCssClass={styles.fileList}
          />
        )}

        <h2 className={styles.sectionTitle}>{pageContent?.fileTitle}</h2>
        {pageContent?.files && <FileDownloadList files={pageContent.files} target={Target.NEW_TAB} />}
      </Welcome>
    </>
  );
};

export default ContentWelcome;
