import { Button, THEME } from "@myloc/myloc-gui";
import classNames from "classnames";
import { useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { CircleAddFilled, Menu, Products, User } from "../../../assets/icons";
import Search from "../../../assets/icons/Search";
import { useTranslate } from "../../../language/i18n";
import { openOrderPopup } from "../../../reducers/dialog/dialogAction";
import { selectHasFSPAccess } from "../../../reducers/session/sessionSelectors";
import pages from "../../../utils/pages";
import MenuDrawer from "./MenuDrawer/MenuDrawer";
import styles from "./MobileMenu.module.scss";
import SearchDrawer from "./SearchDrawer/SearchDrawer";

function MobileMenu() {
  const translate = useTranslate();
  const userLoggedIn = !!useSelector(state => state.session.sessionId);
  const order = useSelector(state => state.appData.order);
  const [menuVisible, setMenuVisible] = useState("");
  const hasFSPAccess = useSelector(selectHasFSPAccess);

  const onItemClick = e => {
    e.preventDefault, menuVisible && toggleDrawer();
  };

  const toggleDrawer = menu => {
    setMenuVisible(menuVisible === menu ? "" : menu);
  };

  return (
    <>
      {userLoggedIn && (
        <>
          <section className={styles.menu}>
            {hasFSPAccess && (
              <>
                <button
                  className={classNames(styles.link, menuVisible && styles.active)}
                  onClick={e => {
                    e.preventDefault;
                    toggleDrawer("SEARCH");
                  }}
                >
                  <Search />
                  <span className={styles.text}>{translate("SEARCH")}</span>
                </button>

                <NavLink
                  to={pages.PRODUCTS.PATH}
                  className={styles.link}
                  activeClassName={styles.active}
                  onClick={e => onItemClick(e)}
                >
                  <Products />
                  <span className={styles.text}>{translate(pages.PRODUCTS.NAME)}</span>
                </NavLink>

                <Button
                  theme={THEME.PLAIN}
                  customCssClass={styles.link}
                  onClick={e => {
                    onItemClick(e), openOrderPopup({ receiverType: order?.receiver?.type });
                  }}
                >
                  <CircleAddFilled />
                  <span className={styles.text}>{translate(order?.receiver?.type ? "ADD_TO_ORDER" : "NEW_ORDER")}</span>
                </Button>

                <NavLink
                  to={pages.MY_MYLOC.PATH}
                  className={styles.link}
                  activeClassName={styles.active}
                  onClick={e => onItemClick(e)}
                >
                  <User />
                  <span className={styles.text}>{translate("MY_MYLOC")}</span>
                </NavLink>
              </>
            )}
            <button
              className={classNames(styles.link, menuVisible && styles.active)}
              onClick={e => {
                e.preventDefault;
                toggleDrawer("MENU");
              }}
            >
              <Menu />
              <span className={styles.text}>{translate("MENU")}</span>
            </button>
          </section>

          <MenuDrawer visible={menuVisible === "MENU"} onMenuLinkClick={() => toggleDrawer("MENU")} />
          <SearchDrawer visible={menuVisible === "SEARCH"} onMenuLinkClick={() => toggleDrawer("SEARCH")} />
        </>
      )}
    </>
  );
}

export default MobileMenu;
