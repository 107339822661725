import { Method, Request, sendRequest } from "@myloc/myloc-utils";
import defaultRestOptions from "../../../../services/utils/defaultRestOptions";
import { api } from "../config/settings";
import { BankIdAuthData, BankIdAuthResponse, BankIdCollectData, BankIdCollectResponse } from "../types";

export async function initiateAuth(): Promise<BankIdAuthResponse> {
  const url = api.bankid.initiateAuth();
  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, {}, await defaultRestOptions());

  const bankIdAuthResponse: BankIdAuthResponse = {
    isOk: response.isOk.bind(response),
    message: response.message,
    data: typeof response.data === "object" ? (response.data as BankIdAuthData) : undefined,
  };

  return bankIdAuthResponse;
}

export type OrderRef = { orderRef: string };

export async function collect(orderRef: OrderRef): Promise<BankIdCollectResponse> {
  const url = api.bankid.collect();

  const request = new Request(url, Method.POST);

  const response = await sendRequest(request, orderRef, await defaultRestOptions());

  const bankIdResponse: BankIdCollectResponse = {
    isOk: response.isOk.bind(response),
    message: response.message,
    data: typeof response.data === "object" ? (response.data as BankIdCollectData) : undefined,
  };

  return bankIdResponse;
}
