import { useSelector } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import ScrollToTop from "../../utils/ScrollToTop";
import useInit from "../../utils/useInit";
import LoadingSpinner from "../shared/Spinner/LoadingSpinner";
import UserFeedback from "../shared/UserFeedback/UserFeedback";
import EmbeddedRouter from "./EmbeddedRouter";
import Footer from "./Footer/Footer";
import HeaderSelector from "./HeaderSelector";
import Main from "./Main";
import MobileMenuSelector from "./MobileMenuSelector";
import PageRouter from "./PageRouter";
import UserLoggedInComponents from "./UserLoggedInComponents";

const RoutedSite = () => {
  const isEmbedded = /^\/embedded\//.test(window.location.pathname);

  return (
    <>
      {isEmbedded ? (
        <>
          <ScrollToTop />
          <EmbeddedRouter />
          <UserFeedback />
        </>
      ) : (
        <>
          <HeaderSelector />
          <Main>
            <PageRouter />
            <UserFeedback />
          </Main>
          <Footer />
          <MobileMenuSelector />
          <UserLoggedInComponents />
        </>
      )}
    </>
  );
};
const Site = () => {
  const sessionInitialized = useSelector(state => state.session?.initialized);

  useInit();

  if (!sessionInitialized) {
    return <LoadingSpinner />;
  }
  return (
    <Router>
      <RoutedSite />
    </Router>
  );
};

export default Site;
