import { Button, RadioButton } from "@myloc/myloc-gui";
import PropTypes from "prop-types";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useParams } from "react-router";
import { useTranslate } from "../../../language/i18n";
import clientService from "../../../services/client/clientService";
import deliveryMethodService from "../../../services/deliveryMethod/deliveryMethodService";
import orderService from "../../../services/order/orderService";
import { ADDRESS_TYPE, CLIENT_TYPE, CONTACT_TYPE } from "../../../utils/constants";
import { AdvanceContactInformation } from "../RecieverInformation/RecieverInformation";
import styles from "./AddressAndContact.module.scss";
import ContactPerson from "./ContactPerson/ContactPerson";
import FacilityAddress from "./FacilityAddress/FacilityAddress";
import FacilityAddressInfo from "./FacilityAddressInfo/FacilityAddressInfo";
import HULAddress from "./HULAddress/HULAddress";
import DeliveryMethods from "./UserAddress/DeliveryMethods/DeliveryMethods";
import UserAddress from "./UserAddress/UserAddress";

function DeliveryAddress({ onNext, disableFacility, showHUL, data, defaultBaseStorage, showDeliveryMethods = false }) {
  const translate = useTranslate();
  const appDataUser = useSelector(state => state.appData.user);
  const receiver = useSelector(state => state.appData.order?.receiver);
  const isActiveReferencedOrder = useSelector(state => state.appData?.referencedOrder);

  const [receiverAddressType, setReceiverAddressType] = useState(
    receiver?.type === CLIENT_TYPE.BASE_STORAGE ? ADDRESS_TYPE.COMPANY : ADDRESS_TYPE.CLIENT,
  );
  const [clientAddresses, setClientAddresses] = useState([]);
  const [clientContacts, setClientContacts] = useState([]);
  const [selectedClientAddressId, setSelectedClientAddressId] = useState();
  const [selectedFacilityAddress, setSelectedFacilityAddress] = useState();
  const [selectedContactId, setSelectedContactId] = useState();
  const [deliveryMethods, setDeliveryMethods] = useState([]);
  const [selectedDeliveryMethod, setSelectedDeliveryMethod] = useState(data?.deliveryMethod);
  const [delivery, setDelivery] = useState({});
  const [useAdvanceContact, setUseAdvanceContact] = useState(receiver?.useAdvanceContact);
  const params = useParams();

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    orderService.getDelivery(params.order, params.content).then(response => setDelivery(response.data));
  }, [params]);

  useEffect(() => {
    if (!deliveryMethods?.length) {
      deliveryMethodService.getDeliveryMethods({ all: true }).then(response => setDeliveryMethods(response.data.page));
    }
  }, [deliveryMethods]);

  //Load
  const reloadClientAddresses = useCallback(async () => {
    if (receiver && receiver.type === CLIENT_TYPE.USER) {
      const addresses = await loadClientAddresses(receiver, isActiveReferencedOrder);
      if (!(addresses?.length === 0)) setClientAddresses(addresses?.addresses);
    }
  }, [receiver, isActiveReferencedOrder]);

  const reloadClientContacts = useCallback(async () => {
    if (receiver && receiver.type === CLIENT_TYPE.USER) {
      const contacts = await loadClientContacts(receiver, isActiveReferencedOrder);
      if (!(contacts?.length === 0)) setClientContacts(contacts?.contacts);
    }
  }, [receiver, isActiveReferencedOrder]);

  useEffect(() => {
    if (receiver) {
      reloadClientAddresses();
      reloadClientContacts();
    }
  }, [receiver, reloadClientAddresses, reloadClientContacts]);

  useEffect(() => {
    const user = appDataUser?.information;

    if (receiver?.type === CLIENT_TYPE.USER && defaultBaseStorage && !delivery?.address) {
      setSelectedContactId(user.id);
      setReceiverAddressType(ADDRESS_TYPE.COMPANY);
    } else if (receiver?.type === CLIENT_TYPE.USER || disableFacility) {
      if (delivery?.address?.id && delivery?.address?.type === ADDRESS_TYPE.CLIENT) {
        setSelectedClientAddressId(delivery?.address?.id);
        setSelectedFacilityAddress(appDataUser?.facility);
      } else if (delivery?.address?.id && delivery?.address?.type === ADDRESS_TYPE.COMPANY) {
        if (delivery?.address?.facility?.category?.value !== "0") {
          setSelectedClientAddressId(receiver?.defaultAddress?.id);
          setSelectedFacilityAddress(showHUL ? data?.fittingType?.executingFacility : delivery?.address?.facility);
        }
      } else {
        setSelectedClientAddressId(receiver?.defaultAddress?.id);
        setSelectedFacilityAddress(showHUL ? data?.fittingType?.executingFacility : appDataUser?.facility);
      }

      setSelectedContactId(delivery?.contact?.id ?? receiver?.defaultContact?.id ?? user.id);
      setReceiverAddressType(delivery?.address?.type ?? receiver?.type);
    } else if (receiver?.type === CLIENT_TYPE.BASE_STORAGE) {
      setSelectedFacilityAddress(receiver?.address);
      setSelectedContactId(user.id);
      setReceiverAddressType(receiver?.address?.type);
    }
  }, [
    delivery.address,
    delivery.contact,
    receiver,
    appDataUser,
    showHUL,
    data?.fittingType?.executingFacility,
    disableFacility,
    defaultBaseStorage,
  ]);

  //On change
  const onClientAddressChange = address => {
    setSelectedClientAddressId(address?.id);
  };

  const onFacilityAddressChange = useCallback(address => {
    setSelectedFacilityAddress(address);
  }, []);

  const onReceiverTypeChange = type => {
    if (type === ADDRESS_TYPE.COMPANY && showHUL) {
      setSelectedFacilityAddress(data?.fittingType?.executingFacility);
    }
    setReceiverAddressType(type);
  };

  const onContactChange = contact => {
    setSelectedContactId(contact?.id);
  };

  const onDeliveryMethodChange = option => {
    setSelectedDeliveryMethod(option);
  };

  const getAddressId = address => {
    if (address?.category?.value === "0") {
      if (showHUL) {
        return address?.id;
      }
    } else {
      return address?.id;
    }
  };

  //Save
  const handleSubmit = async () => {
    setLoading(true);

    const selectedAddress =
      receiverAddressType === ADDRESS_TYPE.COMPANY ? selectedFacilityAddress : getSelectedAddress();
    const selectedContact = getSelectedContact();
    const addressAndContact = {
      contact: { id: selectedContact?.id, type: selectedContact?.type },
      address: {
        id: getAddressId(selectedAddress),
        type: receiverAddressType === ADDRESS_TYPE.COMPANY ? ADDRESS_TYPE.COMPANY : ADDRESS_TYPE.CLIENT,
      },
    };

    const updateDeliveryResponse = await orderService.updateDelivery(addressAndContact, params.order, params.content);

    if (!updateDeliveryResponse.isOk()) {
      setLoading(false);
      return;
    }

    const contactInfoResponse = await clientService.updateClient(receiver.id, {
      contactInformation: receiver.contactInformation,
      useAdvanceContact,
    });

    if (!contactInfoResponse.isOk()) {
      setLoading(false);
      return;
    }

    if (!showDeliveryMethods || receiverAddressType !== ADDRESS_TYPE.CLIENT) {
      setLoading(false);
      onNext(updateDeliveryResponse.data);
      return;
    }

    const updateContentResponse = await orderService.updateContent(params.content, {
      ...data,
      deliveryMethod: selectedDeliveryMethod,
    });

    setLoading(false);

    if (updateContentResponse.isOk()) onNext(updateDeliveryResponse.data);
  };

  //Get
  function getSelectedAddress() {
    if (selectedClientAddressId) return clientAddresses?.find(address => address.id === selectedClientAddressId);
  }

  function getSelectedContact() {
    if (receiver?.type === CLIENT_TYPE.BASE_STORAGE) {
      return { id: appDataUser.information.id, type: CONTACT_TYPE.PERSON };
    }
    cleanSelectedContactId();
    if (selectedContactId) return clientContacts?.find(contact => contact.id === selectedContactId);
  }

  //Removes encoded ¤ from selectedContactId if exist (¤ = %C2%A4);
  function cleanSelectedContactId() {
    if (selectedContactId) {
      const newselectedContactId = selectedContactId.split("%C2%A4");
      if (newselectedContactId.length >= 2) setSelectedContactId(newselectedContactId[1]);
    }
  }

  //Render
  return (
    <section>
      {receiver?.type === CLIENT_TYPE.USER ? (
        <>
          {!disableFacility && (
            <div className={styles.radioButtonGroup}>
              <RadioButton
                label={translate("USER/UNIT")}
                id="user"
                name="address-type"
                checked={receiverAddressType === ADDRESS_TYPE.CLIENT}
                onChange={() => onReceiverTypeChange(ADDRESS_TYPE.CLIENT)}
              />
              {showHUL ? (
                <RadioButton
                  label={data?.fittingType?.executingFacility?.facility?.label}
                  id="hul"
                  name="address-type"
                  onChange={() => onReceiverTypeChange(ADDRESS_TYPE.COMPANY)}
                  checked={receiverAddressType === ADDRESS_TYPE.COMPANY}
                />
              ) : (
                <RadioButton
                  label={translate("BASE_STORAGE")}
                  id="storage"
                  name="address-type"
                  onChange={() => onReceiverTypeChange(ADDRESS_TYPE.COMPANY)}
                  checked={receiverAddressType === ADDRESS_TYPE.COMPANY}
                />
              )}
            </div>
          )}
          {receiverAddressType === CLIENT_TYPE.USER ? (
            <>
              <UserAddress
                user={receiver}
                addresses={clientAddresses}
                selectedAddress={getSelectedAddress()}
                onSelect={onClientAddressChange}
                onReload={reloadClientAddresses}
                required
              />
              {showDeliveryMethods && (
                <DeliveryMethods
                  options={deliveryMethods}
                  selectedId={selectedDeliveryMethod?.id}
                  onSelect={onDeliveryMethodChange}
                />
              )}
            </>
          ) : showHUL ? (
            <HULAddress address={data.fittingType.executingFacility} />
          ) : (
            <FacilityAddress
              onSelect={onFacilityAddressChange}
              preSelected={selectedFacilityAddress}
              disabled={showHUL}
              showHUL={showHUL}
              isActiveReferencedOrder={isActiveReferencedOrder}
            />
          )}
          {<AdvanceContactInformation shouldContact={useAdvanceContact} onChange={setUseAdvanceContact} />}
          {
            <ContactPerson
              user={receiver}
              contacts={clientContacts}
              selectedContact={getSelectedContact()}
              onSelect={onContactChange}
              onReload={reloadClientContacts}
              isActiveReferencedOrder={isActiveReferencedOrder}
              required
            />
          }
        </>
      ) : (
        <FacilityAddressInfo />
      )}
      <Button onClick={handleSubmit} isLoading={isLoading} customCssClass={styles.submitBtn}>
        {translate("NEXT")}
      </Button>
    </section>
  );
}

async function loadClientAddresses(user) {
  if (user) {
    const response = await clientService.getAddress(user.id);
    if (response.isOk()) return response.data;
  }
}

async function loadClientContacts(user, activeReferenceOrder) {
  if (user) {
    const response = await clientService.getContact(
      user.id,
      "",
      activeReferenceOrder?.fitting ? false : true,
      activeReferenceOrder?.fitting ? decodeURIComponent(activeReferenceOrder?.fitting?.prescriber?.id) : "",
    );
    if (response.isOk()) return response.data;
  }
}

DeliveryAddress.propTypes = {
  address: PropTypes.object,
  contact: PropTypes.object,
  disableFacility: PropTypes.bool,
  onNext: PropTypes.func,
  showHUL: PropTypes.bool,
  defaultBaseStorage: PropTypes.bool,
  data: PropTypes.object,
  showDeliveryMethods: PropTypes.bool,
};

export default DeliveryAddress;
