import { useSelector } from "react-redux";
import HoldingPopup from "../shared/HoldingPopup/HoldingPopup";
import OrderPopup from "../shared/OrderPopup/OrderPopup";
import UserMessageToAcceptModal from "../UserSettings/UserMessageToAcceptModal";

const UserLoggedInComponents = () => {
  const userLoggedIn = useSelector(state => {
    return state.session?.sessionId;
  });

  return userLoggedIn ? (
    <>
      <OrderPopup />
      <HoldingPopup />
      <UserMessageToAcceptModal />
    </>
  ) : null;
};

export default UserLoggedInComponents;
