import { dialogVariables } from "@myloc/myloc-utils";
import { AnyAction, Reducer } from "redux";
import { HOLDING_POPUP, ORDER_POPUP } from "./dialogVariable";

const MESSAGE_TYPE = dialogVariables.MESSAGE_TYPE;
const ACTION = dialogVariables.ACTION;

interface Message {
  value?: string;
  error?: string | null;
  type?: typeof MESSAGE_TYPE[keyof typeof MESSAGE_TYPE];
}

export interface DialogState {
  message?: Message;
  orderPopup?: {
    visible: boolean;
    includeNavigation: boolean;
    receiverType: string;
  };
  holdingPopup?: any;
}

const initialState: DialogState = {
  message: {},
  orderPopup: {
    visible: false,
    includeNavigation: true,
    receiverType: "",
  },
};

export const dialogReducer: Reducer<DialogState, AnyAction> = (
  state = initialState,
  action: AnyAction,
): DialogState => {
  switch (action.type) {
    case ACTION.MESSAGE_ERROR:
      return {
        ...state,
        message: {
          value: action.message,
          error: action.error,
          type: MESSAGE_TYPE.ERROR,
        },
      };
    case ACTION.MESSAGE_INFO:
      return {
        ...state,
        message: {
          value: action.message,
          error: action.error,
          type: MESSAGE_TYPE.MESSAGE,
        },
      };

    case ACTION.RESET_MESSAGE: {
      return {
        ...state,
        message: {},
      };
    }

    case ORDER_POPUP:
      return {
        ...state,
        orderPopup: action.orderPopup,
      };
    case HOLDING_POPUP:
      return {
        ...state,
        holdingPopup: action.holdingPopup,
      };
    default:
      return state;
  }
};
