import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useTranslate } from "../../language/i18n";
import { RootState } from "../../reducers/rootReducer";
import { selectHasComflowAccess, selectHasFSPAccess } from "../../reducers/session/sessionSelectors";
import { SESSION_ID } from "../../reducers/session/sessionVariables";
import storage from "../../utils/store/storage";
import SpinningButton, { OUTLINED } from "../shared/Buttons/MuiButton/MuiButton";
import useComflowLogin from "./hooks/useComflowLogin";
import { COMFLOW, isRedirectToComflowAllowed } from "./utils/comflowUtils";

const LinkToComflow: React.FC = () => {
  const { initiateComflowLogin, isLoggedIn } = useComflowLogin();

  const [loading, setLoading] = useState<boolean>(false);
  const message = useSelector((state: RootState) => state.dialog.message);
  const applicationsList = useSelector((state: RootState) => state.session.applicationsList);
  const comflowApp = applicationsList?.find(app => app.id === COMFLOW);

  const hasFSPAccess = useSelector(selectHasFSPAccess);
  const hasComflowAccess = useSelector(selectHasComflowAccess);

  const translate = useTranslate();

  useEffect(() => {
    if (message?.error) {
      setLoading(false);
    }
  }, [message]);

  useEffect(() => {
    const handleRedirection = async () => {
      //Need to manually delete sessionId from session and storage to avoid login loop if returned from Comflow
      //Cannot use logout, since we will lose the session before(?) redirecting
      //Next time user returns login screen will be shown
      await storage.deleteItem(SESSION_ID);

      window.open(process.env.REACT_APP_COMFLOW_WEBAPP, "_self");
    };

    if (isLoggedIn) {
      setLoading(false);
      handleRedirection();
    }
  }, [hasFSPAccess, isLoggedIn]);

  const handleClick = () => {
    initiateComflowLogin();
    setLoading(true);
  };

  if (!isRedirectToComflowAllowed() || !hasComflowAccess) return null;

  return (
    <SpinningButton variant={OUTLINED} loading={loading} buttonAction={handleClick} widthPX={200}>
      {translate("OPEN")} {comflowApp?.label}
    </SpinningButton>
  );
};

export default LinkToComflow;
