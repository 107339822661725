import { createHmac } from "crypto";
import { useCallback, useEffect, useRef, useState } from "react";
import { useTranslate } from "../../../../language/i18n";
import { COMPLETE, PENDING } from "../constants";
import { collect, initiateAuth, OrderRef } from "../services/bankIdService";
import { BankIdAuthData, BankIdCollectData } from "../types";

const MILLISECONDS_IN_SECOND = 1000;
const SECONDS_IN_MINUTE = 60;

export const useBankIdStartAuthentication = (setBankIdError: (error: string) => void) => {
  const [autoStartToken, setAutoStartToken] = useState<string | undefined>("");
  const [bankIdContent, setBankIdContent] = useState<BankIdAuthData | undefined>();
  const translate = useTranslate();

  const resetAuth = () => {
    setAutoStartToken("");
  };

  const handleAuth = async () => {
    const response = await initiateAuth();

    if (!response.isOk()) {
      if (typeof response.message === "string") {
        setBankIdError(response.message);
      }

      return;
    }

    const data = response.data;

    if (data) {
      setBankIdContent(data);
      setAutoStartToken(data.autoStartToken);
    } else {
      setBankIdError(translate("UNKNOWN_ERROR_OCCURRED"));
    }
  };

  const resetBankIdContent = () => {
    setBankIdContent(undefined);
  };

  return { autoStartToken, bankIdContent, handleAuth, resetAuth, resetBankIdContent };
};

export const useQRCode = (bankIdContent: BankIdAuthData | undefined) => {
  const [qrCodeContent, setQrCodeContent] = useState<string>("");
  const intervalRef = useRef<NodeJS.Timeout | null>(null);

  const stopQRGeneration = useCallback(() => {
    if (intervalRef.current) {
      clearInterval(intervalRef.current);
      setQrCodeContent("");
    }
  }, []);

  const bankIdGenerateQRCode = useCallback(
    (time: number) => {
      if (!bankIdContent) return;

      const qrAuthCode = createHmac("sha256", bankIdContent.qrStartSecret)
        .update("" + time)
        .digest("hex");

      const qrContent = `bankid.${bankIdContent.qrStartToken}.${time}.${qrAuthCode}`;

      setQrCodeContent(qrContent);
    },
    [bankIdContent],
  );

  useEffect(() => {
    if (!bankIdContent) return;

    let elapsedTime = 0;

    intervalRef.current = setInterval(() => {
      bankIdGenerateQRCode(elapsedTime);
      elapsedTime += 1;

      if (elapsedTime > SECONDS_IN_MINUTE) {
        stopQRGeneration();
      }
    }, MILLISECONDS_IN_SECOND);

    return () => {
      if (intervalRef.current !== null) {
        clearInterval(intervalRef.current);
      }
    };
  }, [bankIdContent, bankIdGenerateQRCode, stopQRGeneration]);

  return { qrCodeContent, stopQRGeneration };
};

export const useBankIDCollect = (
  bankIdContent: BankIdAuthData | undefined,
  setBankIdError: (error: string) => void,
) => {
  const [collectedResponse, setCollectedResponse] = useState<BankIdCollectData | undefined>(undefined);
  const intervalCollectRef = useRef<NodeJS.Timeout | null>(null);

  const resetCollectedResponse = () => {
    setCollectedResponse(undefined);
  };

  const stopCollectTimer = useCallback(() => {
    if (intervalCollectRef.current) {
      clearInterval(intervalCollectRef.current);
      intervalCollectRef.current = null;
    }
  }, []);

  const resetCollect = useCallback(() => {
    stopCollectTimer();
    resetCollectedResponse();
  }, [stopCollectTimer]);

  const validateBankIdCollect = useCallback(async () => {
    if (!bankIdContent) return;

    const orderRef: OrderRef = { orderRef: bankIdContent.orderRef };
    const response = await collect(orderRef);

    if (!response.isOk()) {
      const msg = typeof response.message === "string" ? response.message : response.message.text;

      setBankIdError(msg);

      return;
    }

    setCollectedResponse(response.data);

    // Continue collecting status until it's no longer pending
    if (response.data?.status !== PENDING) {
      stopCollectTimer();
    }
  }, [bankIdContent, stopCollectTimer, setBankIdError]);

  useEffect(() => {
    if (!bankIdContent?.orderRef) return;

    // Set up the interval to call validateBankIdCollect every 2 seconds
    intervalCollectRef.current = setInterval(() => {
      validateBankIdCollect();
    }, 2 * MILLISECONDS_IN_SECOND);

    return () => stopCollectTimer();
  }, [bankIdContent, validateBankIdCollect, stopCollectTimer]);

  useEffect(() => {
    if (collectedResponse?.status === COMPLETE && bankIdContent?.orderRef) {
      window.location.href = `/?loginToken=${bankIdContent.orderRef}`;
    }
  }, [collectedResponse?.status, bankIdContent?.orderRef]);

  return { collectedResponse, resetCollect };
};
