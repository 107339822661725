import { useMobile } from "../../utils/viewport";
import MobileMenu from "./MobileMenu/MobileMenu";

const MobileMenuSelector = () => {
  const isSmallScreen = useMobile();

  return isSmallScreen && <MobileMenu />;
};

export default MobileMenuSelector;
